import classNames from 'classnames';
import { Button } from 'primereact/button';
import { ReactComponent as AlgopackIcon } from '@shared/images/algopack/AlgopackIcon.svg';
import { ReactComponent as StarIcon } from '@shared/images/svg/star.svg';
import { ReactComponent as Document } from '@shared/images/svg/Document.svg';
import { ReactComponent as ApiIcon } from '@shared/images/algopack/headerBlock/Api.svg';
import { ReactComponent as PythonIcon } from '@shared/images/algopack/headerBlock/Python.svg';
import { ReactComponent as WebUIIcon } from '@shared/images/algopack/headerBlock/WebUI.svg';
import { ReactComponent as PlayIcon } from '@shared/images/svg/playWhite.svg';
import { useAuthorize, scrollElementByRef } from '@libs/utils';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState, getAllSubscriptionsThunk } from '@store/store';
import { useEffect } from 'react';
import type { ProductReq, RefObjectType } from '@libs/types';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';
import { getProductListThunk } from '@store/store/thunk/product.thunk';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

const bodyReq: ProductReq = {
    platform: 'DATASHOP',
    pageable: {
        pageSize: 10,
        pageNumber: 0,
        sortField: 'order',
        sortOrder: 'asc',
    },
};

export const MainLogo = ({ refs }: Props) => {
    const { isAuthorized } = useAuthorize();
    const productGuids = useSelector((state: RootState) => state.product.productGuids);
    const dispatch = useDispatch<AppDispatch>();
    const isSubscribed = useSelector((state: RootState) => state.algopack.isSubscribed);

    useEffect(() => {
        if (isAuthorized && productGuids[ProductMnemoCodes.TPC_ALGO_PACK]) {
            dispatch(getProductListThunk(bodyReq)).then(() => {
                dispatch(getAllSubscriptionsThunk(productGuids[ProductMnemoCodes.TPC_ALGO_PACK]));
            });
        }
    }, [isAuthorized, productGuids]);

    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <section className={classNames(styles.mainLogoContainer)}>
            <div className={classNames(styles.header, 'flex', 'align-items-baseline')}>
                <AlgopackIcon />
            </div>
            <div className={classNames(styles.tabsWrapper, 'flex')}>
                <div className={classNames('flex', 'align-items-center')}>
                    <ApiIcon />
                    <span>API</span>
                </div>
                <div className={classNames('flex', 'align-items-center')}>
                    <PythonIcon />
                    <span>Python</span>
                </div>
                <div className={classNames('flex', 'align-items-center')}>
                    <WebUIIcon />
                    <span>Web UI</span>
                </div>
            </div>
            <p>Алгоритмическая торговля через API: данные, сигналы и аналитика.</p>
            <p>
                ALGOPACK предоставляет доступ к большому набору исторических и онлайн данных по всем акциям MOEX. Теперь
                клиенты смогут тестировать и запускать собственных торговых роботов на уникальных данных
            </p>
            {isSubscribed
? (
                <div className={classNames(styles.authBtns, 'flex')}>
                    <Link to={`${window._env_.REACT_APP_DS_URL}/products/algopack/visual`} reloadDocument>
                        <PlayIcon />
                        <span>Открыть</span>
                    </Link>
                    <a
                        className={classNames(styles.startLink)}
                        href="https://moexalgo.github.io/start/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Document />
                        <span>Начать работу</span>
                    </a>
                </div>
            )
: (
                <Button
                    className={classNames(styles.getAccessButton)}
                    onClick={() => handleButtonClick('tariffsRef')}
                    label={'Получить доступ'}
                    icon={<StarIcon />}
                />
            )}
        </section>
    );
};
