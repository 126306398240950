import classNames from 'classnames';
import React from 'react';
import { ReactComponent as LoadImageIcon } from '@shared/images/personalAccount/svg/load_image_icon.svg';
import { ReactComponent as AvatarLoaderIcon } from '@shared/images/personalAccount/svg/avatar_loader_icon.svg';
import { ReactComponent as ErrorAvatarLoaderIcon } from '@shared/images/personalAccount/svg/error_avatar_loader_icon.svg';
import { ReactComponent as SuccessAvatarLoaderIcon } from '@shared/images/personalAccount/svg/success_avatar_loader_icon.svg';

import styles from './styles.module.scss';

const AvatarError = (
    <div
        className={classNames(
            'flex',
            'align-items-center',
            'flex-column',
            'justify-content-center',
            styles.withErrorBlock,
        )}
    >
        <ErrorAvatarLoaderIcon />
        <p>Не удалось загрузить файл</p>
        <span>Загрузить еще раз</span>
    </div>
);

export const EmptyTemplate = (avatarError: boolean) => {
    console.log('avatarError =', avatarError);

    if (avatarError) {
        return AvatarError;
    }

    return (
        <div className={classNames('flex', 'align-items-center', 'flex-column', styles.loadBlock)}>
            <LoadImageIcon />
            <p>Перетащите фото в область</p>
            <p className={classNames(styles.blue)}>или загрузите</p>
            <span>Файл в формате jpeg или png не более 5 Мб.</span>
        </div>
    );
};

export const ItemTemplate = (isLoadingAvatar: boolean, avatarError: boolean, isAvatarLoadedSuccess: boolean) => {
    if (isLoadingAvatar) {
        return (
            <div
                className={classNames(
                    'flex',
                    'align-items-center',
                    'flex-column',
                    'justify-content-center',
                    styles.withLoadingBlock,
                )}
            >
                <AvatarLoaderIcon />
                <p>Идет загрузка</p>
            </div>
        );
    }

    if (avatarError) {
        return AvatarError;
    }

    if (isAvatarLoadedSuccess) {
        return (
            <div
                className={classNames(
                    'flex',
                    'align-items-center',
                    'flex-column',
                    'justify-content-center',
                    styles.withSuccessBlock,
                )}
            >
                <SuccessAvatarLoaderIcon />
                <p>Фотография профиля успешно загружена</p>
                <span>Изменить</span>
            </div>
        );
    }

    return (
        // <div className={classNames('flex', 'align-items-center', 'flex-column', styles.loadBlock)}>
        //   <p> I have loaded </p>
        // </div>
        <></>
    );
};

export const ProgressBarTemplate = () => {
    return <></>;
};

export const HeaderTemplate = (data: any, isDragElementExist: boolean, isLoadingAvatar: boolean) => {
    const { chooseButton } = data;

    return <>{!isDragElementExist && !isLoadingAvatar && chooseButton}</>;
};
