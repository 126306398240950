import React, { useEffect, useState } from 'react';
import { Feedback, Footer, Navigation } from '@libs/components';
import { useParams } from 'react-router-dom';
import DisplayContent from '@modules/Editor/DisplayContent/DisplayContent';
import { getArticle } from '@libs/services/article.service';
import { addMetaTag } from '@libs/utils';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { SharedTitle } from './SharedTitle';

export const ArticlePage = () => {
    const { articleId } = useParams<{ articleId: string }>();
    const [title, setTitle] = useState<string>('');
    const [subtitle, setSubtitle] = useState<string>('');
    const [content, setContent] = useState<any>();
    const [date, setDate] = useState<string>('2024-06-20T08:21:42.700Z'); // Оставить пустую строку
    const [category, setCategory] = useState<string>('Аналитика'); // Оставить пустую строку

    useEffect(() => {
        fetchArticle();
    }, [articleId]);

    const fetchArticle = async () => {
        if (articleId) {
            try {
                const response = await getArticle(articleId);
                setContent(JSON.parse(response.data.content) ? JSON.parse(response.data.content).content : undefined);
                setCategory(response.data.category);
                setTitle(response.data.title);
                setSubtitle(response.data.subtitle || '');

                const { title } = response.data;
                document.title = title;
                addMetaTag('title', `${title} | Полезные статьи для инвесторов от DATASHOP`);
                addMetaTag(
                    'description',
                    `${title} - Последние тенденции в мире компаний и инвестиционных возможностей, а также список проверенных приложений и платформ для управления вашими деньгами.`,
                );

                if (response.data.titleDate) setDate(response.data.titleDate);
            } catch (error) {
                console.error('Error fetching article:', error);
            }
        }
    };

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <div className={styles.articlePageWrapper}>
                        <SharedTitle date={date} category={category} />
                        <div className={classNames(styles.titleWrapper, 'flex', 'flex-column')}>
                            <h2>{title}</h2>
                            <p>{subtitle}</p>
                        </div>
                        {content && <DisplayContent content={content} />}
                        <SharedTitle date={date} category={category} />
                    </div>
                    <Feedback />
                </div>
            </div>
            <Footer />
        </>
    );
};
