import { PythonIcon, TelegramIcon } from '@shared';
import DocumentIcon from '@shared/images/algopack/documentIcon.svg';
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import type { RootState } from '@store/store';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';

import styles from './style.module.scss';
import { ContactArticle } from './ContactArticle';

export const Links = () => {
    const productList = useSelector((state: RootState) => state.product.productList);

    return (
        <section className={classNames(styles.linkSection, 'flex')}>
            <h2>Ссылки</h2>
            <div className={classNames('flex')}>
                <ContactArticle
                    title={'Документация'}
                    text={`Полное описание возможностей и данных ${productList.find((item) => item.mnemoCode === ProductMnemoCodes.TPC_ALGO_PACK)?.name} вы можете найти в документации`}
                    buttonText={'Смотреть'}
                    svg={DocumentIcon}
                    link={'https://moexalgo.github.io/'}
                    blankLink
                />
                <ContactArticle
                    title={'Python библиотека'}
                    text={`Для упрощения работы с ${productList.find((item) => item.mnemoCode === ProductMnemoCodes.TPC_ALGO_PACK)?.name} API разработана Python библиотека`}
                    buttonText={'Подключить'}
                    link={'https://pypi.org/project/moexalgo/'}
                    blankLink
                    svg={PythonIcon}
                />
                <ContactArticle
                    title={`${productList.find((item) => item.mnemoCode === ProductMnemoCodes.TPC_ALGO_PACK)?.name} в Telegram`}
                    text={`Вопросы по продуктам ${productList.find((item) => item.mnemoCode === ProductMnemoCodes.TPC_ALGO_PACK)?.name} и общение в сообществе алготрейдеров`}
                    buttonText={'@moex_algopack'}
                    link={window._env_.REACT_APP_MOEXALGO_TELEGRAM}
                    blankLink
                    svg={TelegramIcon}
                />
            </div>
        </section>
    );
};
