import classNames from 'classnames';
import { advantagesList } from '@modules/Investorpro/modules/LandingPage/components/Advantages/data';
import { AdvantageItem } from '@modules/Investorpro/modules/LandingPage/components/Advantages/AdvantageItem';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';
import { useSelector } from 'react-redux';
import type { RootState } from '@store/store';

import styles from './styles.module.scss';

export const Advantages = () => {
    const productList = useSelector((state: RootState) => state.product.productList);

    return (
        <section className={classNames(styles.advantagesWrapper, 'flex')}>
            {advantagesList(productList.find((item) => item.mnemoCode === ProductMnemoCodes.INVESTOR_PRO)?.name).map(
                (item) => (
                    <AdvantageItem key={item.title} {...item} />
                ),
            )}
        </section>
    );
};
