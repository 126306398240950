import React, { useEffect } from 'react';
import { Footer, Navigation } from '@libs/components';

import { SearchPage } from './components';

export function SearchMainPage() {
    useEffect(() => {
        document.title = 'Поиск';
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <SearchPage />
                </div>
            </div>
            <Footer />
        </>
    );
}
