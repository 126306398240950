import { useEffect } from 'react';
import { Feedback, Footer, Navigation } from '@libs/components';
import { addMetaTag, removeMetaTag } from '@libs/utils';

import { DatashopTitle } from './components/DatashopTitle';
import { Products } from './components/Products';

export const MainPage = () => {
    useEffect(() => {
        document.title = 'DATASHOP';
        addMetaTag('title', 'Сервис для инвесторов DATASHOP: акции, облигации, аналитика рынка инвестиций');
        addMetaTag(
            'description',
            'Полезные финансовые сервисы для инвесторов, трейдеров и других участников рынка. На сайте вы найдёте подборку онлайн сервисов для инвестиций и получите доступ к актуальному анализу ценных бумаг и рекомендациям, которые позволят сформировать сбалансированный инвестиционный портфель.',
        );

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <DatashopTitle />
                    <Products />
                    <Feedback />
                </div>
            </div>
            <Footer />
        </>
    );
};
