import { TariffCard } from '@libs/components/pageBlocks/TariffBlock/components/TariffCard';
import { Button } from 'primereact/button';
import { useAuthorize } from '@libs/utils';
import { IS_INVESTORPRO_REDIRECT_PARAMETER } from '@libs/utils/hooks/useAuthorize';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { RootState } from '@store/store';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';

import styles from './styles.module.scss';

export const InvestorProCard = () => {
    const { isAuthorized, loginHandler } = useAuthorize();
    const productList = useSelector((state: RootState) => state.product.productList);

    return (
        <TariffCard
            title={'Ознакомительный'}
            description={'Доступно зарегистрированному пользователю:'}
            list={['Демо-портфель']}
            footerBlock={
                isAuthorized
? (
                    <div className={styles.investorproLink}>
                        <Link to={`${window._env_.REACT_APP_INVESTOR_PRO_LINK}?${IS_INVESTORPRO_REDIRECT_PARAMETER}`}>
                            <span>
                                Открыть{' '}
                                {productList.find((item) => item.mnemoCode === ProductMnemoCodes.INVESTOR_PRO)?.name}
                            </span>
                        </Link>
                    </div>
                )
: (
                    <Button
                        label={'Получить доступ'}
                        onClick={() => loginHandler({ isInvestorproRedirect: true })}
                        className={styles.investorproBtn}
                    />
                )
            }
        />
    );
};
