import React, { useEffect } from 'react';
import { Feedback, Footer, Navigation } from '@libs/components';
import { SearchMainBlock } from '@modules/HelpCenter/HelpCenterPage/components/SearchMainBlock';
import { ProductsList } from '@modules/HelpCenter/HelpCenterPage/components/ProductsList';
import { addMetaTag, removeMetaTag } from '@libs/utils';

export function HelpCenter() {
    useEffect(() => {
        document.title = 'Справочный центр';
        addMetaTag('title', 'Справочный центр по сервисам Datashop: FAQ, справки и обзоры продуктов');
        addMetaTag(
            'description',
            'В справочном центре вы найдёте ответы на часто задаваемые вопросы, подробные справки по всем нашим продуктам и обзоры, которые помогут вам максимально использовать потенциал Datashop. Откройте для себя все возможности сервисов Datashop с помощью нашего справочного центра.',
        );

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <SearchMainBlock />
                    <ProductsList />
                    <Feedback />
                </div>
            </div>
            <Footer />
        </>
    );
}
