import React, { type ChangeEvent, useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, avatarRemoveThunk, avatarUploadThunk, type RootState } from '@store/store';
import { readAsDataURL } from '@libs/utils';
import { resetData, setAvatarError } from '@store/store/slices/user.slice';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';

import styles from './styles.module.scss';
import { EmptyTemplate, HeaderTemplate, ItemTemplate, ProgressBarTemplate } from './components/uploadTemplates';
import { ChangeAvatar } from './components/ChangeAvatar';

export const ChangeAvatarForm = ({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) => {
    const [isDragElementExist, setIsDragElementExist] = useState<boolean>(false);
    const [imageStr, setImageStr] = useState<string>('');
    const [isLoadingAvatar, setLoadingAvatar] = useState<boolean>(false);
    const imageAvatarSelector = useSelector((state: RootState) => state.user.userAvatar);
    const avatarError = useSelector((state: RootState) => state.user.avatarError);
    const isAvatarLoadSuccess = useSelector((state: RootState) => state.user.isAvatarLoadSuccess);

    const dispatch = useDispatch<AppDispatch>();
    const fileUploadRef = useRef<FileUpload>(null);

    useEffect(() => {
        dispatch(setAvatarError(false));
    }, [isOpen]);

    const changeImageHandler = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
        const imageFile = e.target.files?.length ? e.target.files[0] : null;

        if (imageFile) {
            const { data } = await readAsDataURL(imageFile);
            dispatch(avatarUploadThunk(imageFile));
            setImageStr(data as string);
        }
    }, []);

    const allowDrop = useCallback(() => {
        setIsDragElementExist(true);
    }, []);

    const allowAddImageFromFileSystem = useCallback(() => {
        setIsDragElementExist(false);
    }, []);

    const removeImage = useCallback(async () => {
        setLoadingAvatar(true);
        await dispatch(avatarRemoveThunk());
        setLoadingAvatar(false);

        if (!avatarError) {
            fileUploadRef.current?.clear();
        }
    }, [avatarError]);

    const chooseOptions = { className: styles.chooseArea };

    const customBase64Uploader = async (event: any) => {
        setLoadingAvatar(true);
        dispatch(resetData());
        const file = event.files[0];
        await dispatch(avatarUploadThunk(file));
        setLoadingAvatar(false);
    };

    return (
        <form className={classNames(styles.formWrapper)}>
            <h2>фото профиля</h2>
            <ChangeAvatar
                img={imageStr || imageAvatarSelector}
                changeImageHandler={changeImageHandler}
                removeImage={removeImage}
            />
            <div
                onDragEnter={allowDrop}
                onDragOver={allowDrop}
                onDragEnd={allowAddImageFromFileSystem}
                onDragLeave={allowAddImageFromFileSystem}
                className={classNames(
                    styles.fileUpload,
                    isLoadingAvatar && styles.withLoadingBlock,
                    avatarError && styles.fileUploadError,
                    imageAvatarSelector && isAvatarLoadSuccess && !isLoadingAvatar && styles.withUploadSuccess,
                )}
            >
                <FileUpload
                    onBeforeDrop={allowAddImageFromFileSystem}
                    ref={fileUploadRef}
                    auto
                    mode={'advanced'}
                    chooseOptions={chooseOptions}
                    customUpload
                    uploadHandler={customBase64Uploader}
                    headerTemplate={(data) => HeaderTemplate(data, isDragElementExist, isLoadingAvatar)}
                    itemTemplate={() => ItemTemplate(isLoadingAvatar, avatarError, Boolean(imageAvatarSelector && isAvatarLoadSuccess))}
                    progressBarTemplate={ProgressBarTemplate}
                    emptyTemplate={() => EmptyTemplate(avatarError)}
                    accept="image/*"
                    maxFileSize={1048576 * 5}
                    onValidationFail={() => {
                        dispatch(setAvatarError(true));
                    }}
                />
            </div>
            <Button
onClick={closeModal} type="button" role="button"
label={'Закрыть'} />
        </form>
    );
};
