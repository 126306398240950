const RUSQUANT = (productName?: string) => ({
    name: 'RUSQUANT',
    label: '1 место GO ALGO',
    text: `GIGAPACK - вычислительный слой над ${productName}, дает доступ инвесторам к агрегированной микроструктуре фондового рынка и ее альтернативной истории`,
    color: 'yellow',
    fullHTmlDescription:
        `<div><p>Cервис упрощающий использование ${productName} для долгосрочных инвесторов и позволяющий избегать переобучения при построении торговых стратегий.</p><p>Предлагаемый инвестиционный сервис ориентирован на аудиторию алготрейдеров и состоит из трех главных элементов, каждый из которых доступен по API:</p><ul><li>Консолидатор рыночных данных и данных ${productName} в объект, который мы назвали GigaCandles;</li><li>Стандартные технические индикаторы для удобства пользования;</li><li>И ключевой элемент: синтетические рыночные данные (AlterGiga).</li></ul></div>`,
    videoLink: 'https://www.youtube.com/watch?v=hlP8DPYyY-Y',
    codeLink: 'https://github.com/arbuzovv/GigaPack?tab=readme-ov-file',
    presentationLink:
        'https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FQNwLehYMto6SvyHUNnQj2BC5hLNiiFPJRGbITXHHLLMAxryjeuS%2FIla%2BVKC%2BshTdq%2FJ6bpmRyOJonT3VoXnDag%3D%3D%3A%2Frusquant.pdf&name=rusquant.pdf',
    gideLink: 'https://rpubs.com/arbuzov/momentum',
});

const WISEPLAT = (productName?: string) => ({
    name: 'WISEPLAT',
    label: 'HOW TO GUIDE',
    text: `Интеграция ${productName} и BACKTRADER`,
    color: 'blue',
    videoLink: 'https://www.youtube.com/watch?v=SmcQF2jPxsQ',
    codeLink: 'https://github.com/WISEPLAT/backtrader_moexalgo',
    presentationLink:
        'https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FQNwLehYMto6SvyHUNnQj2BC5hLNiiFPJRGbITXHHLLMAxryjeuS%2FIla%2BVKC%2BshTdq%2FJ6bpmRyOJonT3VoXnDag%3D%3D%3A%2FWISEPLAT.pdf&name=WISEPLAT.pdf&nosw=1',
    gideLink: 'https://github.com/WISEPLAT/Hackathon-MOEX-How-To-Guide',
    fullHTmlDescription:
        '<p>WISEPLAT победитель номинации «HOW TO GUIDE» GO ALGO</p><h6>Библиотека backtrader_moexalgo делает интеграцию с Backtrader и позволяет:</h6><ul><li>Тестировать стратегии на исторических данных с биржи MOEX\n</li> <li>Запускать торговые системы для автоматической торговли на бирже MOEX через брокеров: Финам, Алор и других, у кого есть терминал Quik</li> <li>Загружать live, исторические данные и SuperCandles</li> <li>Создавать и тестировать свои торговые стратегии пользуясь возможностями Backtrader</li></ul>',
});

const coreDump = (productName?: string) => ({
    name: 'Core Dump',
    label: 'Участник хакатона',
    text: 'Пример стратегии парного трейдинга',
    color: 'gray',
    codeLink: 'https://github.com/novichkovg/pairs_trading_algopack/blob/main/pairs_trading_with_algopack.ipynb',
    fullHTmlDescription:
        `<p>Пример стратегии парного трейдинга на данных ${productName} </p>`,
});

const AiScalp = {
    name: 'AISCALP',
    label: '2 место GO ALGO',
    color: 'gray',
    text: 'Платформа торговых алгоритмов',
    videoLink: 'https://drive.google.com/file/d/1bJPF__QqMn0dnD56TCjHKMz_bkg7wvYb/view',
    codeLink: 'https://github.com/Timkar164/aiscalp-goalgo',
    presentationLink: 'https://drive.google.com/file/d/1MFzZ60_ODNzEUAROxz28W_pNBa2PBXQb/view',
    fullHTmlDescription:
        '        <h6>Для активных трейдеров</h6>\n' +
        '        <ul>' +
        '          <li>Модели машинного обучения для внутридневной торговли</li>' +
        '          <li>Уведомления об аномальном поведении на основе данных ALGOPACK</li>\n' +
        '          <li>Маркетплейс моделей и алгоритмов от сообщества</li>\n' +
        '          <li>BI аналитика состояния рынка</li>\n' +
        '        </ul>\n' +
        '        <h6> Для инвесторов</h6>\n' +
        '        <ul>\n' +
        '          <li>AI модели подбора портфеля</li>\n' +
        '          <li>AI аналитика портфеля и построение прогноза по его динамике </li>\n' +
        '          <li>Сервис лучшей цены, с учетом капитала и проскальзываний</li>\n' +
        '        </ul>\n' +
        '        <h6>Для разработчиков</h6>\n' +
        '        <ul>\n' +
        '          <li>Маркетплейс алгоритмов, позволяющий создавать стратегии</li>\n' +
        '          <li>Облачная платформа для разработки стратегий</li>\n' +
        '        </ul>',
};

const NullPointerException = (productName?: string) => ({
    name: 'NullPointerException',
    label: '3 место GO ALGO',
    color: 'orange',
    text: `Платформа по построению торговых и инвестиционных стратегий на данных ${productName}`,
    videoLink: 'https://www.youtube.com/watch?v=X952ia86A90',
    codeLink: 'https://github.com/DaniilVdovin/GO.ALGO.Neuro',
    presentationLink:
        'https://github.com/DaniilVdovin/GO.ALGO.Neuro/blob/main/%D0%9F%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%BD%D0%BE%D0%B5%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5.pdf',
    fullHTmlDescription:
        `<div><p>Платформа по построению торговых и инвестиционных стратегий на данных ${productName}</p><p>Реализована как low | no-code, что позволяет формировать стратегии не специалистам. </p><p>Имеет возможности кастомизации на всех этапах \n` +
        'построения стратегий, что позволяет создавать стратегии любой сложности\n</p></div>',
});

const GoodGenius = (productName?: string) => ({
    name: 'Good Genius',
    label: 'Выбор инвестора',
    color: 'blue',
    text: 'AlgoMaker – no-code сервис создания алгоритмов',
    videoLink: 'https://www.youtube.com/watch?v=JcTBZzHn96M',
    codeLink: '',
    presentationLink:
        'https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FQNwLehYMto6SvyHUNnQj2BC5hLNiiFPJRGbITXHHLLMAxryjeuS%2FIla%2BVKC%2BshTdq%2FJ6bpmRyOJonT3VoXnDag%3D%3D%3A%2FGood%20Genius.pptx&name=Good%20Genius.pptx&nosw=1',
    gideLink: 'https://drive.google.com/file/d/1C1qr-Ejj_zfh0HmAQBGHGRFeVEnMuNUw/view',
    fullHTmlDescription:
        '<p>Good Genius победитель номинации «Выбор инвестора» GO ALGO.</p><p>Сервис для создания алгоритмов трейдинга и проведения \n' +
        `тестирования на исторических данных ${productName}</p><h6>Сервис поможет тем:\n</h6><ul><li>кто начинает изучать алготрейдинг</li><li>инвесторам которым нужны алгоритмы</li></ul>`,
});

export const examplesUnion = (productName?: string) => [
    RUSQUANT(productName),
    AiScalp,
    NullPointerException(productName),
    GoodGenius(productName),
    WISEPLAT(productName),
    coreDump(productName),
] as Array<SolutionType>;

export type SolutionType = {
    name: string;
    label?: string;
    text: string;
    color?: string;
    videoLink?: string;
    codeLink: string;
    fullHTmlDescription: string;
    presentationLink?: string;
    gideLink?: string;
};
