import React, { useRef } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import styles from './styles.module.scss';
import { ReactComponent as ArrowRed } from '../../../../../../../../shared/images/svg/ArrowRed.svg';

export const EmptyProductCard = () => {
    const location = useLocation();

    return (
        <div
            className={classNames(
                styles.goToProductWrapper,
                'flex',
                'flex-column',
                'align-items-center',
                'justify-content-center',
            )}
        >
            <ArrowRed />
            <p>
                Больше продуктов, сервисов и данных от Московской Биржи и партнеров можете найти на главной странице
                DATASHOP.
            </p>
            <Link to="/" state={{ ...location.state, goToProducts: true }}>
                <span>Выбрать продукты</span>
            </Link>
        </div>
    );
};
