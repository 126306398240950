import { Button } from 'primereact/button';
import callIcon from '@shared/images/personalAccount/svg/call_icon.svg';
import dataIcon from '@shared/images/personalAccount/svg/data_icon.svg';
import dataIconWhite from '@shared/images/personalAccount/svg/data_icon_white.svg';
import emptyAvatar from '@shared/images/personalAccount/svg/empty_avatar.svg';
import emailIcon from '@shared/images/personalAccount/svg/email_icon.svg';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { ModalWindow } from '@libs/components';
import { ReactComponent as ChangeAvatarIcon } from '@shared/images/personalAccount/svg/add_avatar_icon.svg';
import { generateCramlLink, useModal } from '@libs/utils';
import { ChangeEmailComponent } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/AccountCard/components/ChangeEmailComponent';
import { GetUserDataForm } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/AccountCard/components/GetUserDataForm';
import { ChangeAvatarForm } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/AccountCard/components/ChangeAvatarForm';
import { AuthContext, type IAuthContext } from 'react-oauth2-code-pkce';

import styles from './styles.module.scss';

type AccountCardProps = {
    imageStr?: string;
    fullName: string;
    phoneNumber?: string;
    isEmailVerified?: boolean;
    email?: string;
    personalDataProvided: boolean;
};

export const AccountCard = ({
    imageStr,
    fullName,
    phoneNumber,
    isEmailVerified,
    email,
    personalDataProvided,
}: AccountCardProps) => {
    const [isOpenEditModal, setOpenEditModal] = useState<boolean>(false);
    const [showChangeForm, setShowChangeForm] = useState<boolean>(!(email && isEmailVerified));
    const hideModal = () => setOpenEditModal(false);
    const { tokenData } = useContext<IAuthContext>(AuthContext);

    // change avatar modal
    const { isOpen, openModal, closeModal } = useModal();

    const goToMobileRegistration = () => {
        window.location.href = generateCramlLink(
            window._env_.REACT_APP_AUTH_REALMS,
            window._env_.REACT_APP_REALM,
            window._env_.REACT_APP_PROVIDER_MOBILEID,
            window._env_.REACT_APP_CLIENT_ID,
            encodeURI(window.location.href.split('?')[0] + '?showOnboarding=true'),
            tokenData?.session_state,
            window._env_.REACT_APP_CLIENT_ID,
        );
    };

    useEffect(() => {
        setShowChangeForm(!(email && isEmailVerified));
    }, [email, isEmailVerified]);

    return (
        <div className={classNames(styles.accountCardWrapper)}>
            <div className={classNames(styles.blueTop)} />
            <div className={classNames(styles.cardInnerWrapper, 'flex', 'flex-column', 'align-items-center')}>
                <div role={'button'} onClick={openModal} className={classNames(styles.imgWrapper)}>
                    <img src={imageStr || emptyAvatar} />
                    <ChangeAvatarIcon />
                </div>
                <p>{fullName}</p>
                <div className={styles.greyLine} />
                <div className={classNames('inline-flex', styles.infoWrapper)}>
                    <img src={callIcon} />
                    <p>{phoneNumber || '-'}</p>
                </div>
                {email && isEmailVerified && !showChangeForm
? (
                    <div className={classNames('inline-flex', styles.infoWrapper)}>
                        <img src={emailIcon} />
                        <p>{email}</p>
                        <p onClick={() => setShowChangeForm(true)}>Изменить</p>
                    </div>
                )
: (
                    <ChangeEmailComponent
                        isEditEmail={!!(email && isEmailVerified)}
                        isEmailVerified={isEmailVerified}
                        email={email || ''}
                        setShowChangeForm={setShowChangeForm}
                    />
                )}
                {personalDataProvided
? (
                    <Button
                        className={classNames(
                            'inline-flex',
                            'align-items-center',
                            'justify-content-center',
                            styles.editButton,
                        )}
                        onClick={() => setOpenEditModal(true)}
                    >
                        <img src={dataIcon} />
                        <span>Личные данные</span>
                    </Button>
                )
: (
                    <Button
                        className={classNames(
                            'inline-flex',
                            'align-items-center',
                            'justify-content-center',
                            styles.editButton,
                            styles.redBtn,
                        )}
                        onClick={goToMobileRegistration}
                    >
                        <img src={dataIconWhite} />
                        <span>Указать данные</span>
                    </Button>
                )}
            </div>
            <ModalWindow className={styles.getInfoModal} closeModal={hideModal} isVisible={isOpenEditModal}>
                <GetUserDataForm closeOuterModal={hideModal} />
            </ModalWindow>
            <ModalWindow closeModal={closeModal} isVisible={isOpen} className={styles.modalWrapper}>
                <ChangeAvatarForm isOpen={isOpen} closeModal={closeModal} />
            </ModalWindow>
        </div>
    );
};
