import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    avatarDownloadThunk,
    avatarRemoveThunk,
    avatarUploadThunk,
    editUserThunk,
    getPersonalInformationThunk,
    getUserThunk,
    updateEmailThunk,
} from '../thunk';
import { PersonalInfo, UserProfileType } from '@libs/types';
import { SubscriptionData } from '@libs/types/subscription.type';
import { subscriptionThunk, updateApiKeyThunk } from '@store/store/thunk/user/subscription.thunk';

export type IState = {
    user?: UserProfileType;
    userAvatar?: string;
    isLoading: boolean;
    avatarError: any;
    error: any;
    isAvatarLoadSuccess: boolean;
    subscriptionList: SubscriptionData[];
    personalInfo: PersonalInfo;
    showOnboardingModal: boolean;
};

export const initialState: IState = {
    user: undefined,
    isLoading: false,
    avatarError: '',
    userAvatar: '',
    error: '',
    isAvatarLoadSuccess: false,
    subscriptionList: [],
    personalInfo: {},
    showOnboardingModal: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetData(state) {
            state.avatarError = '';
            state.isAvatarLoadSuccess = false;
        },
        setShowOnboardingModal(state, action: PayloadAction<boolean>) {
            state.showOnboardingModal = action.payload;
        },
        setAvatarError(state, action: PayloadAction<boolean>) {
            state.avatarError = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(avatarUploadThunk.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(avatarUploadThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = { ...(state.user as UserProfileType), photoUrl: action.payload?.uploadData };
                state.userAvatar = action.payload?.imageStr as string;
                state.isAvatarLoadSuccess = true;
            })
            .addCase(avatarUploadThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.avatarError = 'Avatar upload error';
            })

            .addCase(avatarDownloadThunk.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(avatarDownloadThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userAvatar = action.payload as string;
            })
            .addCase(avatarDownloadThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            })
            .addCase(editUserThunk.fulfilled, (state, action) => {
                state.user = action.payload?.data;
            })
            .addCase(getUserThunk.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getUserThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
                if (!state.showOnboardingModal) {
                    state.showOnboardingModal = !action.payload.onboardingWasShown;
                }
            })
            .addCase(getUserThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            })

            .addCase(avatarRemoveThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(avatarRemoveThunk.fulfilled, (state) => {
                state.isLoading = false;
                state.avatarError = '';
                state.isAvatarLoadSuccess = false;
                state.user = { ...(state.user as UserProfileType), photoUrl: undefined };
                state.userAvatar = undefined;
            })
            .addCase(avatarRemoveThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            })
            .addCase(subscriptionThunk.fulfilled, (state, action) => {
                state.subscriptionList = action.payload;
            })
            .addCase(updateApiKeyThunk.fulfilled, (state, action) => {
                state.subscriptionList = state.subscriptionList.map((item) =>
                    item.id === action.payload.id ? action.payload : item,
                );
            })
            .addCase(getPersonalInformationThunk.fulfilled, (state, action) => {
                state.personalInfo = action.payload;
            })
            .addCase(updateEmailThunk.fulfilled, (state, action) => {
                state.user = action.payload;
            });
    },
});

export const userReducer = userSlice.reducer;
export const { resetData, setShowOnboardingModal, setAvatarError } = userSlice.actions;
