import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Notification, type NotificationResponse } from '@libs/types';
import {
    getExtraNotificationsThunk,
    getNotificationsThunk,
    getUnreadNotificationsCountThunk,
    setNotificationStatusThunk,
} from '@store/store/thunk/notification.thunk';

type AddImageArgs = {
    notificationId: number;
    image: string;
};

type InitialState = {
    readNotifications: number[];
    notificationResponse: NotificationResponse['data'] | null;
    notifications: Notification[];
    unreadNotificationsCount: number;
    notificationImages: Record<string, string>;
};

const initialState: InitialState = {
    readNotifications: [],
    notificationResponse: null,
    notifications: [],
    unreadNotificationsCount: 0,
    notificationImages: {},
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addReadNotification: (state, action: PayloadAction<number>) => {
            state.readNotifications.push(action.payload);
        },
        addNotificationImage: (state, action: PayloadAction<AddImageArgs>) => {
            const { notificationId, image } = action.payload;
            state.notificationImages[notificationId] = image;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getNotificationsThunk.fulfilled, (state, action) => {
            state.notifications = action.payload?.rows ?? [];
            state.notificationResponse = action.payload ?? null;
        });
        builder.addCase(getExtraNotificationsThunk.fulfilled, (state, action) => {
            const newNotifications = action.payload?.rows ?? [];

            state.notifications = [...state.notifications, ...newNotifications];
            state.notificationResponse = action.payload ?? null;
        });
        builder.addCase(setNotificationStatusThunk.fulfilled, (state) => {
            state.readNotifications = [];
            state.unreadNotificationsCount = 0;
        });
        builder.addCase(getUnreadNotificationsCountThunk.fulfilled, (state, action) => {
            state.unreadNotificationsCount = action?.payload?.unread ?? 0;
        });
    },
});

export const notificationsReducer = notificationsSlice.reducer;

export const { addReadNotification, addNotificationImage } = notificationsSlice.actions;
