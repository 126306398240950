import map from '@shared/images/algopack/opportunities/map.png';
import candles from '@shared/images/algopack/opportunities/candles.png';
import superCandles from '@shared/images/algopack/opportunities/superCandles.png';
import megaAlerts from '@shared/images/algopack/opportunities/megaAlerts.png';
import hi2 from '@shared/images/algopack/opportunities/hi2.png';
import futoi from '@shared/images/algopack/opportunities/futoi.png';
import futoi2 from '@shared/images/algopack/opportunities/futoi2.png';

export const tabs = [
    {
        code: 'FUTOI',
        title: 'Визуализация сигналов ALGOPACK',
        description:
            'Онлайн открытые позиции физических и юридических лиц по фьючерсным контрактам. Обновляется каждые 5 мин',
        image: futoi,
        link: `${window._env_.REACT_APP_DS_URL}/products/algopack/visual?tab=futoi`,
    },
    {
        code: 'Карта рынка',
        title: 'Визуализация сигналов ALGOPACK',
        description: 'Текущее состояние всех акций и фьючерсов на одном экране',
        image: map,
        link: `${window._env_.REACT_APP_DS_URL}/products/algopack/visual?tab=market_map`,
    },
    {
        code: 'Candles',
        title: 'Визуализация сигналов ALGOPACK',
        description: 'Свечной график по акциям',
        image: candles,
        link: `${window._env_.REACT_APP_DS_URL}/products/algopack/visual?tab=candles`,
    },
    {
        code: 'SuperCandles',
        title: 'Визуализация сигналов ALGOPACK',
        description: 'SuperCandles - расширенные 5 мин свечи с разбивкой на покупателей и продавцов',
        image: superCandles,
        link: `${window._env_.REACT_APP_DS_URL}/products/algopack/visual?tab=super_candles`,
    },
    {
        code: 'MegaAlerts',
        title: 'Визуализация сигналов ALGOPACK',
        description:
            'Показывает все акции, которые торгуются сегодня с аномалиями: большие объемы и резкие изменения цены',
        image: megaAlerts,
        link: `${window._env_.REACT_APP_DS_URL}/products/algopack/visual?tab=mega_alerts`,
    },
    {
        code: 'HI2',
        title: 'Визуализация сигналов ALGOPACK',
        description:
            'Индекс Херфиндаля-Хиршмана (индекс рыночной концентрации) — это ключевой показатель для оценки концентрации рынка и уровня конкуренции среди участников',
        image: hi2,
        link: `${window._env_.REACT_APP_DS_URL}/products/algopack/visual?tab=hi2`,
    },
    {
        code: 'FUTOI 2.0',
        title: 'Визуализация сигналов ALGOPACK',
        description:
            'Онлайн открытые позиции физических и юридических лиц по фьючерсным контрактам. Обновляется каждые 5 мин',
        image: futoi2,
        link: `${window._env_.REACT_APP_DS_URL}/products/algopack/visual?tab=futoi_2`,
    },
];
