import type { TariffFilter as TariffFilterReq } from '@libs/types';

export const tariffBodyReq: TariffFilterReq = {
    pageable: {
        pageSize: 10,
        pageNumber: 0,
        sortField: 'id',
        sortOrder: 'asc',
    },
};
