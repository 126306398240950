import icon1 from '../../../../shared/images/svg/advantagesIcon/icon1.svg';
import icon2 from '../../../../shared/images/svg/advantagesIcon/icon2.svg';
import icon3 from '../../../../shared/images/svg/advantagesIcon/icon3.svg';
import icon4 from '../../../../shared/images/svg/advantagesIcon/icon4.svg';

export const advantagesList = (name: string = 'Инвестор Про') => [
    {
        title: 'Избавьтесь от ежедневной рутины и хаоса',
        icon: icon1,
        description:
            'Актуальные данные по портфелям Ваших клиентов всегда под рукой – проверяйте и предлагайте идеи, выгружайте отчеты',
    },
    {
        title: 'Освободите время для работы с клиентами',
        icon: icon2,
        description:
            `Больше не нужно вести портфели в Excel – ${name} возьмет на себя все сложные портфельные расчеты и предоставит самые актуальные данные в понятном и удобном виде`,
    },
    {
        title: 'Всесторонний анализ и данные в едином окне',
        icon: icon3,
        description:
            `Больше не придется переключаться между окнами и вкладками – ${name} предоставляет исчерпывающие данные и возможности для подбора и анализа акций, облигаций и фондов`,
    },
    {
        title: 'Презентуйте идеи и отчеты на новом уровне',
        icon: icon4,
        description:
            'Интуитивно понятный конструктор отчетов с возможностью брендирования поможет сформировать под каждого клиента и каждый портфель персональный отчет. Повышайте лояльность клиентов, упаковывая Ваши идеи и экспертизу по высоким стандартам.',
    },
];
